<template>
  <base-content-layout
    :visibleDataTable="visibleDataTable"
    :error="currentError"
    :loading="isLoading"
    :record="record"
    v-on:alertVisibilityChanged="onAlertVisibilityChanged"
    v-on:expandedPreview="onExpandedPreview"
  >
    <!-- module details (project-details) -->
    <template v-slot:module-details>
      <project-details :record="record" :project="project"></project-details>
    </template>

    <!-- record details -->
    <template v-slot:record-details>
      <project-record-details></project-record-details>
    </template>

    <!-- Sub record list -->
    <template v-slot:record-list v-if="visibleSubRecords">
      <project-documents v-show="visibleSubRecords"></project-documents>
    </template>

    <!-- record history -->
    <template v-slot:record-history v-if="visibleFileVersions">
      <project-file-versions
        v-show="visibleFileVersions"
      ></project-file-versions>
    </template>

    <!-- Preview -->
    <template v-slot:record-preview v-if="visiblePreviewTab">
      <!--
        TODO: enable DocumentViewerPreview when conflict of
        preview/DocumentEditorDialog is resolved
      -->
      <!-- Preview record-image-pdf-file ONLY when expanded Preview -->
      <document-viewer
        v-if="visibleDocumentViewerPreview"
        :record-id="recordId"
        :record-version="previewRecordVersion"
        :preview-only="true"
        :is-open-document="expandedPreview"
      ></document-viewer>

      <!-- Preview current record's file(s) -->
      <document-preview
        v-if="visibleDocumentPreview"
        :record="record"
        :open="expandedPreview"
      ></document-preview>
    </template>

    <!-- e.g. How to use Snackbar if/when needed -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        >{{ computedSnackbarText }}</snackbar
      >
    </template>
  </base-content-layout>
</template>

<script>
import { projectContentMixin } from "@/mixins/project/content/projectContentMixin";

export default {
  name: "ProjectContent",
  mixins: [projectContentMixin],
  data() {
    return {
      panelDocuments: undefined,
      panelHistory: undefined
    };
  },
  components: {
    ProjectDetails: () => import("@/views/projects/ProjectDetails"),
    ProjectRecordDetails: () => import("@/views/projects/ProjectRecordDetails"),
    ProjectDocuments: () => import("@/views/projects/ProjectDocuments"),
    ProjectFileVersions: () => import("@/views/projects/ProjectFileVersions"),
    DocumentPreview: () => import("@/views/document/DocumentPreview"),
    DocumentViewer: () => import("@/views/document/DocumentViewer")
  }
};
</script>

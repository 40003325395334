// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("project");

// mixins
import { moduleContentRecordMixin } from "@/mixins/shared/content/moduleContentRecordMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

/**
 * Project Content Mixin
 */
export const projectContentMixin = {
  mixins: [moduleContentRecordMixin, reactiveMixin],
  computed: {
    /**
     * Overwrite moduleContentRecordMixin.record()
     */
    ...mapState({
      /**
       * Get current selected Project Module's record
       * Overwrite moduleContentRecordMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[] }}
       */
      record: "record",

      /**
       * Get current selected Project Module's Project
       * @return {{projectId: Number, projectName:string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}}
       */
      project: "project"
    })
  },
  methods: {
    /**
     * Overwrite moduleContentRecordMixin's methods
     */
    ...mapActions({
      /**
       * Set current selected Project Module's Record
       * Overwrite moduleContentRecordMixin.setRecord()
       */
      setRecord: "setRecord",

      /**
       *  Set current selected Project Module's Project
       *  Overwrite moduleContentMixin.setModuleItem()
       */
      setModuleItem: "setProject"
    })
  },
  watch: {
    /**
     * watch current selected project
     * @param {{projectId: Number, projectName:string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}} newProject
     * @return {Promise<void>}
     */
    async project(newProject) {
      try {
        this.clearError();
        const recordId = newProject?.recordId ?? -1;
        await this.setRecord(recordId);
      } catch (e) {
        this.handleError(e);
      }
    }
  }
};
